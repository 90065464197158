<template>
  <div class="article_list">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">반려한 문서함</h2>
    </div>
    <search-div
      title-search="검색"
      title-date="상신일시"
      title-complete="반려일시"
      :search-select-options="[
        { code: 'docNo', name: '문서번호' },
        { code: 'docTitle', name: '제목' },
        { code: 'menuId', name: '구분' },
      ]"
      :is-detail-page="true"
      :is-approval="true"
      :is-complete="true"
      @init-search-form="initSearchForm"
      @select-connector="selectConnector"
    />
    <!-- :open-search-box="true" -->
    <list-div
      ref="docList"
      :open-doc-no="true"
      :is-approval="true"
      :open-draft-date="true"
      :open-reject-date="true"
      :doc-flag="'AR'"
      :doc-status="docStatus.REJECT"
      :aprvl-status="approvalStatus.ALL"
      :doc-list="docList"
      :total-page="totalPage"
      :listLineDatas="listLineDatas"
      @go-detail="goDetail"
      @get-doc-list="getDocList"
    />
  </div>
</template>

<script>
import SearchDiv from "@/_approval/components/shared/list/SearchDiv";
import ListDiv from "@/_approval/components/shared/list/ListDiv";
import { approvalStatus, docStatus } from "@/_approval/const/const";
import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";

export default {
  name: "ApprRejctDocs",
  components: { ListDiv, SearchDiv },
  mixins: [commDocs],
  data() {
    return {
      searchForm: {},
      totalPage: 0,
      currentPage: 0,
      docList: [],
      approvalStatus: approvalStatus,
      docStatus: docStatus,

      listLineDatas: [
        { colStyle: "width: 56px", key: "checkbox" },
        { text: "문서번호", colStyle: "width: 180px", key: "docNo" },
        { text: "구분", colStyle: "width: 120px", key: "targetGubun" },
        {
          text: "제목",
          colStyle: "",
          key: "docTitle",
          class: "cell_left td_ellip",
        },
        {
          text: "기안요청자",
          colStyle: "width: 152px",
          key: "drftAccountId",
          key2: "drftEmpName",
        },
        { text: "상신일시", colStyle: "width: 106px", key: "drftDate" },
        { text: "반려일시", colStyle: "width: 106px", key: "docStatusDate" },
      ],
    };
  },
  methods: {
    selectConnector(formData) {
      this.searchForm = formData;
      localStorage.searchForm = JSON.stringify(this.searchForm);
      this.$refs.docList.resetSort(1); // 서칭시 페이지 초기화로 reset
    },
    initSearchForm(formData) {
      this.searchForm = formData;
    },
    async getDocList(pagingConfig, sort) {
      const params = this.makeBaseDocListParams(pagingConfig, this.searchForm);
      params.aprvlStatus = approvalStatus.ALL;
      params.docStatus = docStatus.REJECT;

      const res = await ApiService.shared.get(`${this.$apiPath.APRVL_DOC_LIST}`, {
        params: params,
      });
      if (!this.checkNormalResponse(res)) {
        return;
      }

      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => (e.regDate = this.strDateTimeSplit(e.regDate)));
        res.data.forEach((e) => (e.docStatusDate = this.strDateTimeSplit(e.docStatusDate)));
      }

      this.totalPage = parseInt(res.total);
      this.docList = res.data;
    },
    goDetail(docNo) {
      this.$router.push({ name: "ApprRejectDetail", query: { docNo: docNo } });
    },
  },
};
</script>
